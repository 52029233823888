import { useState, useEffect, useRef } from 'react';
import punkmap from '../punkmap.json';

const tabs = [
  { name: 'base' },
  { name: 'hair' },
  { name: 'eyes' },
  { name: 'mouth' },
  { name: 'nose' },
  { name: 'beard' },
  { name: 'eyewear' },
  { name: 'headwear' },
  { name: 'jewellery' },
  { name: 'extras' },
];

const options = {
  base: ['ape_male', 'ape_female'],
  clothing: ['ape_male'],
};

//console.log(punkmap.filter((punk) => punk.part === 'base'));

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function TabBar({ punk, setPunk }) {
  const [activeTab, setActiveTab] = useState('base');
  const punk_base_gender = punk.base.gender;
  const punk__base_size = punk.base.size;
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current.scrollTo(0, 0);
  }, [activeTab]);

  const handleOnClick = (item) => {
    // console.log('current: ', punk[activeTab].id);
    // console.log('new: ', item.id);

    const { [activeTab]: remove, ...rest } = punk;
    // console.log('punk_before: ', punk);
    // console.log('remove: ', remove);
    // console.log('rest: ', rest);

    // if the item being selected is a base
    if (item.part === 'base') {
      // reduce the punk object to remove uncompatible parts
      const filtered = Object.keys(punk).reduce((obj, part) => {
        let partMatchesBase =
          // does the part not equal base (bases might not match which is fine)
          punk[part].part !== 'base' &&
          // does the part not equal backrgound (background does not have a gender or size)
          punk[part].part !== 'background' &&
          // does the part match the base's gender or is it universal?
          (punk[part].gender === item.gender || punk[part].gender === 'u') &&
          // does the part match the base's size or is it universal?
          (punk[part].size === item.size || punk[part].size === 'u');
        // if the part matches the base then add it to the new object
        partMatchesBase && (obj[part] = punk[part]);
        // return the new object containing only matching parts
        return obj;
      }, {});

      // set punk to the same background, the new base and only the filtered parts
      setPunk({ background: punk.background, base: item, ...filtered });
      // return so that state is not set again below
      return;
    }

    if (item.extra) {
      const part_name = `${activeTab}_${item.id}`;

      const { [part_name]: remove, ...rest } = punk;

      item.id === punk[`${activeTab}_${item.id}`]?.id
        ? setPunk({ ...rest })
        : setPunk({ ...punk, [part_name]: item });
      return;
    }

    item.id === punk[activeTab]?.id && activeTab !== 'base'
      ? setPunk({ ...rest })
      : setPunk({ ...punk, [activeTab]: item });
  };

  const handleOnClickNone = () => {
    //function to remove all selected parts on the active tab

    //an array of parts that match the active tab (including extras)
    const extras = Object.keys(punk).filter((part) => part.match(activeTab));

    //filter out the matching parts above from the rest of the parts to get an array of parts that we want to keep
    let partsToKeep = Object.keys(punk).filter(
      (part) => !extras.includes(part)
    );

    // reduce the punk object to keep only the parts we want to keep
    const filtered = Object.keys(punk).reduce((obj, part) => {
      // if the part matches the parts we want to keep then add it to the new object
      let keep = partsToKeep.includes(punk[part].part);
      keep && (obj[part] = punk[part]);
      // return the new object containing only matching parts
      return obj;
    }, {});

    // set punk to the same background and only the filtered parts
    setPunk({ background: punk.background, ...filtered });
  };

  return (
    <div>
      <div className="block">
        <div className="border-b-2 border-gray-200">
          <nav
            className="my-2 flex overflow-x-scroll sm:overflow-visible space-x-2 sm:grid sm:grid-cols-5 sm:space-x-0"
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => setActiveTab(tab.name)}
                className={classNames(
                  activeTab === tab.name
                    ? 'bg-indigo-100 text-indigo-700'
                    : 'text-gray-500 hover:text-gray-700',
                  'whitespace-nowrap px-4 py-3 font-medium text-sm rounded-md'
                )}
                aria-current={activeTab === tab.name ? 'page' : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
        {/* Tab Content */}
        <div className="">
          <div>
            <div className="overflow-auto h-56 sm:h-72" ref={scrollRef}>
              {activeTab === 'base' && (
                <div className="rounded-md bg-blue-50 p-1 mt-2 mx-1 mb-0">
                  <div className="flex items-center space-x-2">
                    <div className="flex-shrink-0">
                      <span className="h-5 w-5 text-blue-400">ℹ️</span>
                    </div>

                    <p className="text-xs text-blue-700">
                      Other attributes are filtered depending on the base.
                    </p>
                  </div>
                </div>
              )}
              <div className="grid grid-cols-4 auto-rows-min">
                {activeTab !== 'base' && (
                  <button
                    className="bg-gray-100 border-transparent m-1 flex flex-col items-center mt-2 border-2 rounded-lg relative focus:outline-none focus:ring-2  focus:ring-indigo-500"
                    onClick={() => handleOnClickNone()}
                  >
                    <span className="flex items-center justify-center h-12 w-12">
                      🚫
                    </span>
                    <span className="text-xs sm:text-sm">None</span>
                  </button>
                )}
                {punkmap
                  .filter((punk) =>
                    //if the active tab is base
                    activeTab === 'base'
                      ? // show all the base options
                        punk.part === activeTab
                      : // otherwise filter the options more
                        punk.part === activeTab &&
                        // show only parts that match the base's gender or are universal
                        (punk.gender === punk_base_gender ||
                          punk.gender === 'u') &&
                        // show only parts that match the base's size or are universal
                        (punk.size === punk__base_size || punk.size === 'u')
                  )
                  .map((item) => (
                    <button
                      key={item.id}
                      className={classNames(
                        item.id === punk[activeTab]?.id ||
                          item.id === punk[`${activeTab}_${item.id}`]?.id
                          ? 'bg-indigo-100 border-indigo-700'
                          : 'bg-gray-100 border-transparent',
                        'm-1 flex flex-col items-center mt-2 border-2 rounded-lg relative'
                      )}
                      onClick={() => handleOnClick(item)}
                    >
                      <img
                        src="spritesheet.png"
                        alt=""
                        className="object-none h-12 w-12 pointer-events-none select-none"
                        //let x = draw[part].id - (draw[part].id - 25) * 2;
                        //let y = Math.ceil(draw[part].id / 25);
                        style={{
                          objectPosition:
                            100 -
                            (item.id === 0
                              ? 100
                              : (25 * Math.ceil(item.id / 25 + 0.01) -
                                  (item.id + 1)) *
                                (100 / 24)) +
                            '% ' + //0
                            (item.id === 0
                              ? 0
                              : (Math.ceil(item.id / 25 + 0.01) - 1) *
                                (100 / 29)) +
                            //3.5333333333333337 +
                            '%', //3.33
                        }}
                      />
                      <span className="text-xs sm:text-sm select-none">
                        {item.name}
                      </span>
                      {item.extra && (
                        <span className="flex items-center justify-center absolute text-xs sm:text-sm top-0 right-0 h-4 w-4 sm:h-5 sm:w-5 m-0.5 rounded-full bg-green-100 border border-green-500 font-bold text-green-500 select-none">
                          +
                        </span>
                      )}
                    </button>
                  ))}
              </div>
            </div>
          </div>
          {/* <div>{activeTab}</div> */}
        </div>
      </div>
    </div>
  );
}
