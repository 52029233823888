import { useCallback, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';

import useClickOutside from '../hooks/useClickOutside';

export const PopoverPicker = ({ color, onChange }) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);
  const presetColors = ['#8e6fb6', '#cd9323', '#1a53d8', '#9a2151', '#0d6416'];

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  return (
    <div className="absolute bottom-0 left-0 md:left-40">
      <div
        className="w-10 h-10 rounded-md cursor-pointer border-2 border-gray-300"
        style={{ backgroundColor: color }}
        onClick={() => toggle(true)}
      />

      {isOpen && (
        <div className="absolute z-50 mt-2" ref={popover}>
          <HexColorPicker color={color} onChange={onChange} />
          <div className="flex  bg-white rounded-md">
            {presetColors.map((presetColor) => (
              <button
                key={presetColor}
                className="w-6 h-6 m-2 rounded-md cursor-pointer"
                style={{ background: presetColor }}
                onClick={() => onChange(presetColor)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
