import useCanvas from '../hooks/useCanvas';
import punkmap from '../punkmap.json';

const Canvas = (props) => {
  //console.log(punkmap);
  const { draw, bgColour, ...rest } = props;

  //console.log(draw);

  const punk = (ctx) => {
    ctx.fillStyle = bgColour;
    ctx.fillRect(0, 0, 240, 240);

    Object.keys(draw)
      .filter((part) => part !== 'background')
      .map((part) => {
        //console.log(draw[part]);
        //var punk_part = new Image();
        //punk_part.onload = () => {
        // let x = draw[part].id - (draw[part].id - 25) * 2;
        // let y = Math.ceil(draw[part].id / 25);

        let y = draw[part].id === 0 ? 1 : Math.ceil(draw[part].id / 25);
        let x = 25 * y - draw[part].id === 0 ? 25 : 25 * y - draw[part].id;

        (x === 25 && draw[part].id !== 0) || draw[part].id === 25 ? y++ : '';

        ctx.drawImage(
          document.getElementById('punksheet'),
          // 240 * draw[part].x - punk_part.width,
          // (-1 + draw[part].y) * -240
          // 240 * 1 - punk_part.width,
          // (-1 + 2) * -240
          240 * x - document.getElementById('punksheet').width,
          (-1 + y) * -240
        );
        //console.log('id: ' + draw[part].id + ', x: ' + x + ', y: ' + y);
        // console.log(Math.ceil(draw[part].id / 25));
        // console.log(draw[part].id - (draw[part].id - 25) * 2);
        //console.log(x);
        //console.log(y);

        let test_id = 25;
        //let test_y = test_id === 0 ? 1 : Math.ceil(test_id / 25);
        // let test_x = test_id - (test_id - 25) * 2 > 25 ? 'gt 25' : 'lt 25';
        let test_y =
          test_id === 0
            ? 1
            : Number.isInteger(Math.ceil(test_id / 25))
            ? Math.ceil(test_id / 25) + 1
            : Math.ceil(test_id / 25);
        let test_x = 25 * test_y - test_id === 0 ? 25 : 25 * test_y - test_id;

        let new_test_x = 25;

        // console.log(
        //   'test id: ' +
        //     test_id +
        //     ', text_x: ' +
        //     test_x +
        //     ', test_y: ' +
        //     test_y +
        //     ', new_test_x: ' +
        //     new_test_x
        // );
        //};
        //punk_part.src = 'punksheet' + '.png';
      });

    // var punksheet = new Image();
    // punksheet.onload = () => {
    //   ctx.drawImage(
    //     punksheet,
    //     240 * 13 - punksheet.width,
    //     -240
    //     punksheet.height * 1,
    //     punksheet.width * 1
    //   );
    // };
    // punksheet.src = 'punksheet' + '.png';
  };

  const canvasRef = useCanvas(punk);

  return (
    <canvas
      ref={canvasRef}
      {...rest}
      id="punk_canvas"
      width="240"
      height="240"
    />
  );
};

export default Canvas;
